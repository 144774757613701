import React, { useState, useEffect, useContext } from 'react';
import { collection, addDoc, getDocs, query, where, Timestamp, updateDoc, doc, orderBy, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import Sidebar from '../components/Sidebar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Times.css';
import '../styles/SharedStyles.css';
import { AuthContext } from '../contexts/AuthContext';
import Toast from '../components/Toast';
import { useAccessControl } from '../hooks/useAccessControl';

function Times() {
  const { user, loading } = useContext(AuthContext);
  const { hasAccess, isLoading: accessLoading, error: accessError, productionAccess, departmentAccess } = useAccessControl();
  const [timeEntries, setTimeEntries] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(getWeekDates());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const [timeType, setTimeType] = useState('call');
  const [calculationType, setCalculationType] = useState('-30');
  const [selectedHour, setSelectedHour] = useState('');
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);

  useEffect(() => {
    if (user && hasAccess) {
      fetchTimeEntries(selectedDate);
    }
  }, [user, selectedDate, hasAccess]);

  function getWeekDates(date = new Date()) {
    // Get local date components
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const dayOfWeek = date.getDay();

    // Create start date at noon UTC
    const start = new Date(Date.UTC(
      year,
      month,
      day - dayOfWeek,
      12, 0, 0
    ));

    return Array(7).fill().map((_, i) => {
      return new Date(Date.UTC(
        start.getUTCFullYear(),
        start.getUTCMonth(),
        start.getUTCDate() + i,
        12, 0, 0
      ));
    });
  }

  const fetchTimeEntries = async (date = selectedDate) => {
    try {
      console.log("Fetching time entries for user:", user.uid);
      
      // Create UTC dates at noon for consistency with Dispatch.js
      const startOfWeek = new Date(Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate() - date.getUTCDay(),
        12, 0, 0
      ));
      
      const endOfWeek = new Date(Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate() + (6 - date.getUTCDay()),
        12, 0, 0
      ));

      console.log("Date range:", startOfWeek, endOfWeek);

      const timeEntriesRef = collection(db, 'timeEntries');
      const q = query(
        timeEntriesRef,
        where('userId', '==', user.uid),
        where('date', '>=', Timestamp.fromDate(startOfWeek)),
        where('date', '<=', Timestamp.fromDate(endOfWeek)),
        orderBy('date', 'asc')
      );

      console.log("Query:", q);

      const querySnapshot = await getDocs(q);
      console.log("Query snapshot size:", querySnapshot.size);

      const entries = querySnapshot.docs.map(doc => {
        const data = doc.data();
        console.log("Time entry data:", data);
        return {
          id: doc.id,
          ...data,
          date: data.date.toDate(),
          startTime: data.startTime || null,
          endTime: data.endTime || null
        };
      });

      console.log("Processed time entries:", entries);
      setTimeEntries(entries);
    } catch (error) {
      console.error("Detailed error fetching time entries:", error);
      setError(`Failed to fetch time entries: ${error.message}`);
    }
  };

  const handlePreviousWeek = () => {
    const currentDate = new Date(Date.UTC(
      selectedDate.getUTCFullYear(),
      selectedDate.getUTCMonth(),
      selectedDate.getUTCDate() - 7,
      12, 0, 0
    ));
    setSelectedDate(currentDate);
    setSelectedWeek(getWeekDates(currentDate));
    fetchTimeEntries(currentDate);
  };

  const handleNextWeek = () => {
    const currentDate = new Date(Date.UTC(
      selectedDate.getUTCFullYear(),
      selectedDate.getUTCMonth(),
      selectedDate.getUTCDate() + 7,
      12, 0, 0
    ));
    setSelectedDate(currentDate);
    setSelectedWeek(getWeekDates(currentDate));
    fetchTimeEntries(currentDate);
  };

  const handleToday = () => {
    const today = new Date();
    const currentDate = new Date(Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      12, 0, 0
    ));
    setSelectedDate(currentDate);
    setSelectedWeek(getWeekDates(currentDate));
    fetchTimeEntries(currentDate);
  };

  const calculateHoursWorked = (startTime, endTime, type = calculationType) => {
    if (startTime === null || endTime === null) return 0;
    let diff = endTime - startTime;
    if (type === '-30') {
      diff = Math.max(0, diff - 0.5); // Subtract 30 minutes
    }
    return diff;
  };

  const calculateWeekTotal = (type = calculationType) => {
    return timeEntries.reduce((total, entry) => {
      return total + calculateHoursWorked(entry.startTime, entry.endTime, type);
    }, 0).toFixed(2);
  };

  const handleDateChange = (date) => {
    // Get local date components
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    
    // Create UTC date at noon
    const utcDate = new Date(Date.UTC(year, month, day, 12, 0, 0));
    setSelectedDate(utcDate);
    setSelectedWeek(getWeekDates(utcDate));
    fetchTimeEntries(utcDate);
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push(time);
      }
    }
    return options;
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleTimeTypeChange = (e) => {
    setTimeType(e.target.value);
  };

  const handleCalculationTypeChange = (e) => {
    setCalculationType(e.target.value);
  };

  const handleHourChange = (e) => {
    const value = e.target.value;
    // Allow empty string, whole numbers, and .5 increments between 0 and 48
    if (value === '' || (/^\d+(\.\d*)?$/.test(value) && parseFloat(value) >= 0 && parseFloat(value) <= 48)) {
      setSelectedHour(value);
    }
  };

  const convertHourToTime = (hour) => {
    const wholeHour = Math.floor(hour);
    const minutes = hour % 1 === 0.5 ? '30' : '00';
    const adjustedHour = wholeHour > 24 ? wholeHour - 24 : wholeHour;
    return `${adjustedHour.toString().padStart(2, '0')}:${minutes}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!hasAccess || !selectedDate || selectedHour === '') {
      setToast('Please select both date and hour.');
      return;
    }

    console.log('Times.js - Submit button clicked');
    console.log('Times.js - Selected Date:', selectedDate);
    console.log('Times.js - Selected Hour:', selectedHour);

    try {
      const hour = parseFloat(selectedHour);

      // Get local date components from selectedDate
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      const day = selectedDate.getDate();

      // Create entry date at noon UTC
      const entryDate = new Date(Date.UTC(year, month, day, 12, 0, 0));
      
      console.log('Times.js - Entry Date:', entryDate);

      const timeEntryData = {
        userId: user.uid,
        date: Timestamp.fromDate(entryDate),
        [timeType === 'call' ? 'startTime' : 'endTime']: hour,
        log: {
          selectedDate: entryDate.toISOString().split('T')[0],
          clientTime: new Date().toISOString()
        }
      };

      console.log('Times.js - Time Entry Data:', timeEntryData);

      // Check if an entry already exists for this date
      const existingEntryQuery = query(
        collection(db, 'timeEntries'),
        where('userId', '==', user.uid),
        where('date', '==', Timestamp.fromDate(entryDate))
      );
      
      const existingEntrySnapshot = await getDocs(existingEntryQuery);

      if (!existingEntrySnapshot.empty) {
        // Update existing entry
        const existingEntryDoc = existingEntrySnapshot.docs[0];
        await updateDoc(doc(db, 'timeEntries', existingEntryDoc.id), timeEntryData);
        
        // Update local state
        const updatedEntries = timeEntries.map(entry => {
          if (entry.id === existingEntryDoc.id) {
            return {
              ...entry,
              ...timeEntryData,
              date: entryDate
            };
          }
          return entry;
        });
        setTimeEntries(updatedEntries);
      } else {
        // Add new entry
        const docRef = await addDoc(collection(db, 'timeEntries'), timeEntryData);
        setTimeEntries([...timeEntries, {
          id: docRef.id,
          ...timeEntryData,
          date: entryDate
        }]);
      }

      console.log('Times.js - Time entry updated successfully');
      setToast('Time entry updated successfully!');
      setSelectedHour('');
      await fetchTimeEntries(selectedDate);
    } catch (error) {
      console.error('Times.js - Error updating time entry:', error);
      setToast(`Failed to update time entry: ${error.message}`);
    }
  };

  if (accessLoading) {
    return <div>Loading access permissions...</div>;
  }

  if (accessError) {
    return <div>Error: {accessError}</div>;
  }

  if (!hasAccess) {
    return <div>You don't have permission to view this page.</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to view this page.</div>;
  }

  return (
    <div className="times-container">
      <Sidebar />
      <div className="times-content">
        <header className="page-header">
          <h1>Times</h1>
          <p>Track and manage your times</p>
        </header>
        <main>
          {error && <div className="error-message">{error}</div>}
          <div className="times-tile">
            <section className="time-entry-tile">
              <h2>Add/Edit Time Entry</h2>
              <form onSubmit={handleSubmit}>
                <div className="time-entry-inputs">
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                  />
                  <input
                    type="number"
                    step="0.5"
                    min="0"
                    max="48"
                    value={selectedHour}
                    onChange={handleHourChange}
                    placeholder="Enter hour (0-48, 0.5 increments)"
                    required
                  />
                </div>
                <div className="time-type-selector">
                  <label>
                    <input
                      type="radio"
                      value="call"
                      checked={timeType === "call"}
                      onChange={handleTimeTypeChange}
                    />
                    Call Time
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="wrap"
                      checked={timeType === "wrap"}
                      onChange={handleTimeTypeChange}
                    />
                    Wrap Time
                  </label>
                </div>
                <button type="submit">Save Time</button>
              </form>
            </section>
            <section className="weekly-calendar">
              <div className="calendar-navigation">
                <div>
                  <button onClick={() => handleDateChange(new Date(selectedDate.getTime() - 7 * 86400000))}>&lt; Previous Week</button>
                  <button onClick={() => handleDateChange(new Date(selectedDate.getTime() + 7 * 86400000))}>Next Week &gt;</button>
                  <button onClick={() => handleDateChange(new Date())}>Today</button>
                </div>
                <h2>{selectedWeek[0].toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} - {selectedWeek[6].toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</h2>
              </div>
              <div className="calendar-table">
                <div className="calendar-header">
                  <div className="date-cell">Day</div>
                  <div>Call Time</div>
                  <div>Wrap Time</div>
                  <div>Hours<br />Worked</div>
                  <div>Status</div>
                  <div>Actions</div>
                </div>
                <div className="calendar-body">
                  {selectedWeek.map((date, index) => {
                    const entry = timeEntries.find(e => {
                      // Check if e.date is a Timestamp or a Date object
                      const entryDate = e.date instanceof Timestamp ? e.date.toDate() : e.date;
                      return entryDate.toDateString() === date.toDateString();
                    });
                    const hoursWorked = entry ? calculateHoursWorked(entry.startTime, entry.endTime) : '-';
                    const status = entry ? (entry.status || 'Pending') : '-';

                    return (
                      <div key={index} className="calendar-row">
                        <div className="calendar-cell date-cell">
                          <h3>{date.toLocaleDateString('en-US', { weekday: 'long' })}</h3>
                          <p>{date.toLocaleDateString()}</p>
                        </div>
                        <div className="calendar-cell">{entry?.startTime ?? '--'}</div>
                        <div className="calendar-cell">{entry?.endTime ?? '--'}</div>
                        <div className="calendar-cell">{hoursWorked}</div>
                        <div className="calendar-cell">{status}</div>
                        <div className="calendar-cell">
                          <button onClick={() => {/* Implement edit functionality */}}>Edit</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="calculation-type-selector">
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="-30"
                        checked={calculationType === "-30"}
                        onChange={handleCalculationTypeChange}
                      />
                      -30
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="full"
                        checked={calculationType === "full"}
                        onChange={handleCalculationTypeChange}
                      />
                      Full Hours
                    </label>
                  </div>
                  <div className="week-totals">
                    Week Totals: {calculateWeekTotal()} hours
                    <div className="running-totals">
                      -30: {calculateWeekTotal('-30')} hours | Full Hours: {calculateWeekTotal('full')} hours
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      {toast && (
        <Toast 
          message={toast} 
          onClose={() => setToast(null)}
          duration={3000}
        />
      )}
    </div>
  );
}

export default Times;