import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
  orderBy,
  limit,
  deleteDoc,
  batch,
  writeBatch,
  arrayUnion,
  arrayRemove,
  getFirestore
} from 'firebase/firestore';
import { 
  ref, 
  uploadBytes, 
  getDownloadURL,
  uploadBytesResumable,
  deleteObject 
} from 'firebase/storage';
import { storage, db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';
import { 
  canCreateDepartmentChat,
  canSendUrgentMessage,
  canManageDepartmentChats,
  canViewAllDepartmentMessages,
  canDeleteMessages,
  canEditMessages,
  canPinMessages,
  canBroadcastMessage
} from '../utils/permissions';
import Sidebar from '../components/Sidebar';
import ChatModal from '../components/messages/ChatModal';
import './Messages.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import toast from 'react-hot-toast';

const ConversationItem = React.memo(({ 
  chat, 
  selectedChat, 
  pinnedChats, 
  endedConversations, 
  getParticipantDisplayInfo, 
  getConversationDisplayName,
  onSelect 
}) => {
  const { user } = useContext(AuthContext);
  const [displayInfo, setDisplayInfo] = useState({ initials: '', photoURL: null });
  const isEndedForOthers = chat.endedBy && chat.endedBy.length > 0 && !chat.endedBy.includes(user.uid);

  useEffect(() => {
    const loadDisplayInfo = async () => {
      const info = await getParticipantDisplayInfo(chat);
      setDisplayInfo(info);
    };
    loadDisplayInfo();
  }, [chat, getParticipantDisplayInfo]);

  return (
    <div
      className={`conversation-item-content ${
        chat.lastMessagePriority === 'important' ? 'priority-important' : 
        chat.lastMessagePriority === 'urgent' ? 'priority-urgent' : ''
      } ${pinnedChats.includes(chat.id) ? 'pinned' : ''} ${
        isEndedForOthers ? 'ended' : ''
      }`}
    >
      {pinnedChats.includes(chat.id) && (
        <div className="pin-indicator">
          <i className="fas fa-thumbtack"></i>
        </div>
      )}
      <div className="conversation-avatar">
        {displayInfo.photoURL ? (
          <img 
            src={displayInfo.photoURL}
            alt={getConversationDisplayName(chat)}
            className={`avatar-image ${chat.type === 'group' ? 'group-avatar' : ''}`}
            onError={(e) => {
              console.log('Avatar failed to load:', { photoURL: displayInfo.photoURL });
              e.target.style.display = 'none';
              const placeholder = document.createElement('div');
              placeholder.className = `avatar-placeholder ${chat.type === 'group' ? 'group-avatar' : ''}`;
              placeholder.textContent = displayInfo.initials;
              e.target.parentNode.appendChild(placeholder);
              e.target.onerror = null;
            }}
          />
        ) : (
          <div 
            className={`avatar-placeholder ${chat.type === 'group' ? 'group-avatar' : ''}`}
          >
            {chat.type === 'group' ? (
              <i className="fas fa-users"></i>
            ) : (
              displayInfo.initials
            )}
          </div>
        )}
      </div>
      <div className="conversation-info">
        <div className="conversation-header">
          <span className="conversation-name">{getConversationDisplayName(chat)}</span>
          {chat.lastMessageTime && (
            <span className="last-message-time">
              {chat.lastMessageTime.toDate().toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit' 
              })}
            </span>
          )}
        </div>
        <div className="last-message">
          {chat.lastMessage ? (
            <div dangerouslySetInnerHTML={{ 
              __html: chat.lastMessage.split(' ').slice(0, 4).join(' ') + 
                     (chat.lastMessage.split(' ').length > 4 ? '...' : '') 
            }} />
          ) : (
            <span className="no-messages">No messages yet</span>
          )}
        </div>
      </div>
    </div>
  );
});

function Messages() {
  const { user, userRole } = useContext(AuthContext);
  const [conversations, setConversations] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showNewChatModal, setShowNewChatModal] = useState(false);
  const [crewMembers, setCrewMembers] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [messageType, setMessageType] = useState('normal');
  const [newChatDetails, setNewChatDetails] = useState({
    type: 'direct',
    name: '',
    recipients: [],
    department: '',
    priority: 'normal',
    accessLevel: 'all'
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [messageFilter, setMessageFilter] = useState('all');
  const [departmentFilter, setDepartmentFilter] = useState('');
  const [sortBy, setSortBy] = useState('recent');
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [chatParticipants, setChatParticipants] = useState([]);
  const [showDetailsTile, setShowDetailsTile] = useState(true);
  const [departmentNames, setDepartmentNames] = useState({});
  const [formattedRoles, setFormattedRoles] = useState({});
  const [fileErrors, setFileErrors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isMuted, setIsMuted] = useState(false);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [pinnedChats, setPinnedChats] = useState([]);
  const [groupAvatarUrl, setGroupAvatarUrl] = useState('');
  const [endedConversations, setEndedConversations] = useState([]);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const groupAvatarRef = useRef(null);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [showConversationList, setShowConversationList] = useState(true);

  // Permission-based UI controls
  const canCreateDepartment = canCreateDepartmentChat(userRole);
  const canSendUrgent = canSendUrgentMessage(userRole);
  const canManageChats = departmentId => canManageDepartmentChats(userRole, departmentId);
  const canViewDepartment = departmentId => canViewAllDepartmentMessages(userRole, departmentId);
  const canDelete = messageAuthorId => canDeleteMessages(userRole, messageAuthorId, user.uid);
  const canEdit = messageAuthorId => canEditMessages(userRole, messageAuthorId, user.uid);
  const canPin = canPinMessages(userRole);
  const canBroadcast = canBroadcastMessage(userRole);

  // Format file size
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  // Helper function to get user initials from first and last name
  const getInitials = useCallback((firstName, lastName) => {
    if (!firstName && !lastName) return '';
    const first = firstName?.charAt(0) || '';
    const last = lastName?.charAt(0) || '';
    return (first + last).toUpperCase();
  }, []);

  // Get participant display info (initials and photo)
  const getParticipantDisplayInfo = useCallback(async (chat) => {
    if (!chat || !user) return { initials: '', photoURL: null };
    
    if (chat.type === 'group') {
      return {
        initials: chat.name?.charAt(0).toUpperCase() || '',
        photoURL: chat.groupAvatar || null
      };
    }

    // For direct chats, find the other participant
    const otherParticipantId = chat.participants?.find(id => id !== user.uid);
    if (!otherParticipantId) return { initials: '', photoURL: null };

    try {
      // Always fetch fresh user data for avatars
      const userDoc = await getDoc(doc(db, 'users', otherParticipantId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('=== FETCHED USER DATA ===', {
          id: otherParticipantId,
          name: userData.name,
          photoURL: userData.photoURL
        });

        // Get participant data from conversation or use fetched data
        const participantData = chat.participantsData?.[otherParticipantId] || {
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          name: userData.name || ''
        };

        // Get first and last name either from firstName/lastName or by splitting full name
        let first = participantData.firstName;
        let last = participantData.lastName;

        // If we don't have firstName/lastName, try to split the full name
        if ((!first || !last) && participantData.name) {
          const nameParts = participantData.name.split(' ');
          first = first || nameParts[0] || '';
          last = last || nameParts[nameParts.length - 1] || '';
        }

        // Get initials from either source
        const initials = getInitials(first, last);
        
        return {
          initials,
          photoURL: userData.photoURL || null  // Use fresh photoURL from user data
        };
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }

    // Fallback to existing participant data if fetch fails
    const participantData = chat.participantsData?.[otherParticipantId];
    if (!participantData) return { initials: '', photoURL: null };

    let first = participantData.firstName;
    let last = participantData.lastName;

    if ((!first || !last) && participantData.name) {
      const nameParts = participantData.name.split(' ');
      first = first || nameParts[0] || '';
      last = last || nameParts[nameParts.length - 1] || '';
    }

    return {
      initials: getInitials(first, last),
      photoURL: participantData.photoURL || null
    };
  }, [user, getInitials]);

  // Function to get department name from ID
  const getDepartmentName = async (departmentId) => {
    if (!departmentId) return '';
    try {
      const departmentDocRef = doc(db, 'departments', departmentId);
      const departmentDocSnap = await getDoc(departmentDocRef);
      if (departmentDocSnap.exists()) {
        return departmentDocSnap.data().name;
      }
      return '';
    } catch (error) {
      console.error('Error fetching department name:', error);
      return '';
    }
  };

  // Helper function to format role
  const formatRole = async (message) => {
    console.log('Full message data:', message);
    
    if (!message || !message.senderRole || !message.senderDepartment) {
      console.log('Missing required role data');
      return '';
    }
    
    // Only show role for supervisors
    if (!message.senderRole.toLowerCase().includes('supervisor')) {
      console.log('Not a supervisor role');
      return '';
    }

    // Get the department name from the ID
    const departmentName = await getDepartmentName(message.senderDepartment);
    console.log('Department name from ID:', departmentName);
    
    if (!departmentName) {
      console.log('Could not find department name');
      return '';
    }
    
    // Capitalize first letter of department
    const formattedDepartment = departmentName.charAt(0).toUpperCase() + departmentName.slice(1).toLowerCase();
    console.log('Formatted department:', formattedDepartment);
    
    const finalRole = `${formattedDepartment} Supervisor`;
    console.log('Final formatted role:', finalRole);
    
    return finalRole;
  };

  // File handling constants
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
  const ALLOWED_FILE_TYPES = {
    'image/*': 'Image',
    'application/pdf': 'PDF',
    'application/msword': 'Word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word',
    'application/vnd.ms-excel': 'Excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
    'text/plain': 'Text',
    'application/zip': 'ZIP',
    'video/mp4': 'Video',
    'audio/mpeg': 'Audio'
  };

  const validateFile = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      return `${file.name} is too large. Maximum size is ${formatFileSize(MAX_FILE_SIZE)}`;
    }

    const fileType = Object.keys(ALLOWED_FILE_TYPES).find(type => {
      if (type.endsWith('/*')) {
        const baseType = type.split('/')[0];
        return file.type.startsWith(baseType + '/');
      }
      return file.type === type;
    });

    if (!fileType) {
      return `${file.name} has an unsupported file type`;
    }

    return null;
  };

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    const newErrors = [];
    const validFiles = [];

    files.forEach(file => {
      const error = validateFile(file);
      if (error) {
        newErrors.push(error);
      } else {
        validFiles.push(file);
      }
    });

    if (newErrors.length > 0) {
      setFileErrors(newErrors);
      setTimeout(() => setFileErrors([]), 5000); // Clear errors after 5 seconds
    }

    if (validFiles.length > 0) {
      setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
    }

    // Clear the input
    event.target.value = '';
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setUploadProgress(prev => {
      const newProgress = { ...prev };
      delete newProgress[index];
      return newProgress;
    });
  };

  const uploadFile = async (file, index) => {
    const storageRef = ref(storage, `messages/${selectedChat.id}/${Date.now()}_${file.name}`);
    
    const uploadTask = uploadBytesResumable(storageRef, file);
    
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(prev => ({
          ...prev,
          [index]: progress
        }));
      },
      (error) => {
        console.error('Upload error:', error);
        setFileErrors(prev => [...prev, `Failed to upload ${file.name}`]);
      }
    );

    try {
      await uploadTask;
      const downloadURL = await getDownloadURL(storageRef);
      return {
        url: downloadURL,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type
      };
    } catch (error) {
      console.error('Upload error:', error);
      throw error;
    }
  };

  const uploadFiles = async () => {
    setIsUploading(true);

    try {
      const uploadedUrls = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const uploadedFile = await uploadFile(file, i);
        uploadedUrls.push(uploadedFile);
      }
      setIsUploading(false);
      setSelectedFiles([]);
      return uploadedUrls;
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Error uploading files. Please try again.');
    }
  };

  // Fetch conversations with participant data
  useEffect(() => {
    if (!user?.uid) return;

    console.log('Setting up conversations listener');
    const q = query(
      collection(db, 'conversations'),
      where('participants', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      console.log('Conversations snapshot received:', snapshot.size);
      const conversationsData = [];
      
      snapshot.forEach((doc) => {
        conversationsData.push({ id: doc.id, ...doc.data() });
      });
      setConversations(conversationsData);
    });

    return () => unsubscribe();
  }, [user]);

  // Update getConversationDisplayName to use participantsData
  const getConversationDisplayName = useCallback((conversation) => {
    if (!conversation || !user) return 'Unknown';

    // For group chats, use the conversation name
    if (conversation.type === 'group' && conversation.name) {
      return conversation.name;
    }

    // For direct chats, find the other participant
    const otherParticipantId = conversation.participants?.find(id => id !== user.uid);
    if (!otherParticipantId) return 'Unknown User';

    // Use participantsData from the conversation
    const participantData = conversation.participantsData?.[otherParticipantId];
    if (participantData) {
      return participantData.displayName || 
             `${participantData.firstName || ''} ${participantData.lastName || ''}`.trim() || 
             participantData.name || 
             'Unknown User';
    }

    return 'Unknown User';
  }, [user]);

  // Fetch messages for selected chat
  useEffect(() => {
    if (!selectedChat) {
      console.log('No chat selected, skipping message fetch');
      return;
    }

    console.log('Fetching messages for chat:', selectedChat.id);
    console.log('Current user:', user?.uid);

    try {
      // Mark messages as read when chat is selected
      const markMessagesAsRead = async () => {
        try {
          console.log('Starting to mark messages as read for chat:', selectedChat.id);
          
          // Query for both system and user messages that are unread
          const unreadMessagesQuery = query(
            collection(db, 'messages'),
            where('conversationId', '==', selectedChat.id),
            where('read', '==', false)
          );
          
          console.log('Querying unread messages for chat:', selectedChat.id);
          
          const snapshot = await getDocs(unreadMessagesQuery);
          console.log('Found unread messages:', snapshot.size);
          
          if (snapshot.empty) {
            console.log('No unread messages to mark as read');
            return;
          }

          const batch = writeBatch(getFirestore());
          
          snapshot.docs.forEach(doc => {
            const messageData = doc.data();
            // Only mark as read if it's a system message or from another user
            if (messageData.senderId === 'system' || messageData.senderId !== user.uid) {
              console.log('Marking message as read:', doc.id, messageData);
              batch.update(doc.ref, { 
                read: true,
                readAt: serverTimestamp()
              });
            }
          });
          
          console.log('Committing batch update for messages');
          await batch.commit();
          console.log('Successfully marked messages as read');
        } catch (error) {
          console.error('Error marking messages as read:', error);
        }
      };

      // Mark messages as read when chat is selected
      markMessagesAsRead();

      // Mark notifications as acknowledged for this chat
      const markNotificationsAcknowledged = async (conversationId) => {
        try {
          console.log('Marking notifications as acknowledged for conversation:', conversationId);
          const notificationsQuery = query(
            collection(db, 'notifications'),
            where('conversationId', '==', conversationId),
            where('userId', '==', user.uid),
            where('acknowledged', '==', false)
          );

          const snapshot = await getDocs(notificationsQuery);
          console.log('Found notifications to acknowledge:', snapshot.size);

          if (!snapshot.empty) {
            const batch = writeBatch(getFirestore());
            snapshot.forEach(doc => {
              console.log('Marking notification as acknowledged:', doc.id);
              batch.update(doc.ref, { 
                acknowledged: true,
                acknowledgedAt: serverTimestamp()
              });
            });

            await batch.commit();
            console.log('Successfully marked notifications as acknowledged');
          }
        } catch (error) {
          console.error('Error marking notifications as acknowledged:', error);
        }
      };

      // Mark notifications as acknowledged when chat is selected
      markNotificationsAcknowledged(selectedChat.id);

      // Set up messages listener
      const q = query(
        collection(db, 'messages'),
        where('conversationId', '==', selectedChat.id),
        orderBy('timestamp', 'asc')
      );

      console.log('Setting up messages listener');
      const unsubscribe = onSnapshot(q, (snapshot) => {
        console.log('Messages snapshot received:', snapshot.size, 'messages');
        const messagesData = [];
        snapshot.forEach((doc) => {
          const messageData = { id: doc.id, ...doc.data() };
          console.log('Message data:', messageData);
          messagesData.push(messageData);
        });
        setMessages(messagesData);
      }, (error) => {
        console.error('Error in messages snapshot:', error);
      });

      return () => {
        console.log('Cleaning up messages listener');
        unsubscribe();
      };
    } catch (error) {
      console.error('Error setting up messages query:', error);
    }
  }, [selectedChat]);

  // Fetch crew members
  useEffect(() => {
    if (!user?.uid || !user?.production) {
      console.log('Missing required user data for crew members:', { uid: user?.uid, production: user?.production });
      return;
    }

    const fetchCrewMembers = async () => {
      try {
        const q = query(
          collection(db, 'users'),
          where('productionId', '==', user.production)
        );

        const querySnapshot = await getDocs(q);
        const members = [];
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          if (userData.active !== false) {
            members.push({ id: doc.id, ...userData });
          }
        });
        setCrewMembers(members);
      } catch (error) {
        console.error('Error fetching crew members:', error);
      }
    };

    fetchCrewMembers();
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchDepartments();
    }
  }, [user]);

  const fetchDepartments = async () => {
    try {
      const departmentsSnapshot = await getDocs(collection(db, 'departments'));
      const departmentsData = {};
      departmentsSnapshot.forEach(doc => {
        const data = doc.data();
        departmentsData[doc.id] = data.name;
      });
      setDepartmentNames(departmentsData);
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  // Fetch chat participants
  useEffect(() => {
    if (selectedChat) {
      fetchChatParticipants(selectedChat.participants);
    }
  }, [selectedChat]);

  const fetchChatParticipants = async (participantIds) => {
    try {
      const participantsData = [];
      for (const id of participantIds) {
        const userDocRef = doc(db, 'users', id);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const departmentName = await getDepartmentName(userData.department);
          participantsData.push({
            id: userDocSnap.id,
            ...userData,
            departmentName
          });
        }
      }
      setChatParticipants(participantsData);
    } catch (error) {
      console.error('Error fetching chat participants:', error);
    }
  };

  // Check message permissions
  const checkMessagePermissions = (conversation) => {
    if (!user || !conversation) return false;
    
    // Admin and supervisors have full access
    if (user.role === 'admin' || user.role === 'supervisor') return true;
    
    // Department-specific access
    if (conversation.department && conversation.department !== user.department) {
      return conversation.accessLevel === 'all';
    }
    
    // Check role-based access
    if (conversation.accessLevel === 'supervisor' && user.role !== 'supervisor') {
      return false;
    }
    
    return conversation.participants.includes(user.uid);
  };

  // Rich text editor modules configuration
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet'
  ];

  // Helper function to find existing direct conversation
  const findExistingDirectChat = (recipientId) => {
    return conversations.find(chat => 
      chat.type === 'direct' && 
      chat.participants.length === 2 &&
      chat.participants.includes(recipientId) &&
      chat.participants.includes(user.uid)
    );
  };

  // Create new chat with enhanced properties
  const handleCreateChat = async (chatDetails) => {
    console.log('handleCreateChat called in Messages component');
    console.log('User data:', { uid: user?.uid, production: user?.production });
    
    if (!user?.uid || !user?.production) {
      console.error('Missing user data:', { uid: user?.uid, production: user?.production });
      alert('Unable to create chat: Missing user data');
      return;
    }

    try {
      console.log('Processing chat creation with details:', chatDetails);
      
      // For direct chats, check if conversation already exists
      if (chatDetails.type === 'direct' && chatDetails.recipients.length === 1) {
        const existingChat = findExistingDirectChat(chatDetails.recipients[0]);
        if (existingChat) {
          console.log('Found existing chat:', existingChat.id);
          setSelectedChat(existingChat);
          return;
        }
      }

      let participants = [...new Set([user.uid, ...chatDetails.recipients])];
      console.log('Participants:', participants);

      // Get current user's data
      const currentUserDoc = await getDoc(doc(db, 'users', user.uid));
      const currentUserData = currentUserDoc.exists() ? currentUserDoc.data() : {};
      
      // Format current user's display name
      const currentUserDisplayName = `${currentUserData.firstName || ''} ${currentUserData.lastName || ''}`.trim() || 
                                 currentUserData.name || 
                                 'Unknown User';

      // Initialize participantsData with current user's data
      const participantsData = {
        [user.uid]: {
          firstName: currentUserData.firstName || '',
          lastName: currentUserData.lastName || '',
          role: currentUserData.role || '',
          department: currentUserData.department || '',
          title: currentUserData.title || '',
          email: currentUserData.email || '',
          name: currentUserData.name || '',
          photoURL: currentUserData.photoURL || '',  // Add this line to include photoURL
          displayName: currentUserDisplayName
        }
      };

      // Add recipient data from chatDetails
      if (chatDetails.recipientsData) {
        Object.assign(participantsData, chatDetails.recipientsData);
      }

      console.log('Final participants data:', participantsData);

      const newConversation = {
        name: chatDetails.type === 'direct' ? '' : (chatDetails.name || '').trim(),
        type: chatDetails.type,
        createdBy: user.uid,
        participants,
        participantsData,
        productionId: user.production,
        department: chatDetails.department,
        priority: chatDetails.priority || 'normal',
        accessLevel: chatDetails.accessLevel || 'all',
        description: chatDetails.description || '',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        lastMessage: null,
        lastMessageTime: null,
        lastMessageType: 'normal'
      };

      console.log('Adding new conversation to Firestore:', newConversation);
      const docRef = await addDoc(collection(db, 'conversations'), newConversation);
      console.log('Created conversation with ID:', docRef.id);
      
      // Set the newly created chat as selected
      setSelectedChat({ id: docRef.id, ...newConversation });
    } catch (error) {
      console.error('Error creating chat:', error);
      alert('Error creating chat. Please try again.');
    }
  };

  // Update new chat modal options based on permissions
  const handleNewChat = () => {
    const chatOptions = {
      direct: true,
      group: true,
      department: canCreateDepartment,
      broadcast: canBroadcast
    };
    
    setNewChatDetails(prev => ({
      ...prev,
      availableTypes: chatOptions
    }));
    setShowNewChatModal(true);
  };

  // Update message actions based on permissions
  const getMessageActions = (message) => {
    return {
      canEdit: canEdit(message.senderId),
      canDelete: canDelete(message.senderId),
      canPin: canPin
    };
  };

  // Filter department visibility based on permissions
  const getVisibleDepartments = () => {
    return departments.filter(dept => canViewDepartment(dept.id));
  };

  // Update message type options based on permissions
  const getAvailableMessageTypes = () => {
    const types = ['normal'];
    if (canSendUrgent) {
      types.push('urgent', 'important');
    }
    return types;
  };

  // Message action handlers
  const handlePinMessage = async (messageId) => {
    try {
      const messageRef = doc(db, 'messages', messageId);
      const messageDocSnap = await getDoc(messageRef);
      
      if (messageDocSnap.exists()) {
        const currentPinned = messageDocSnap.data().pinned || false;
        await updateDoc(messageRef, {
          pinned: !currentPinned,
          pinnedAt: !currentPinned ? serverTimestamp() : null,
          pinnedBy: !currentPinned ? user.uid : null
        });
      }
    } catch (error) {
      console.error('Error toggling message pin:', error);
    }
  };

  const handleEditMessage = async (message) => {
    try {
      if (message.senderId !== user.uid && !canEdit(message.senderId)) {
        return;
      }

      setSelectedMessage(message);
      setNewMessage(message.text);
      setIsEditing(true);
    } catch (error) {
      console.error('Error preparing message edit:', error);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const messageRef = doc(db, 'messages', messageId);
      const messageDocSnap = await getDoc(messageRef);
      
      if (messageDocSnap.exists()) {
        const messageData = messageDocSnap.data();
        
        if (messageData.senderId !== user.uid && !canDelete(messageData.senderId)) {
          return;
        }

        // Delete any attachments from storage if they exist
        if (messageData.attachments && messageData.attachments.length > 0) {
          for (const attachment of messageData.attachments) {
            const storageRef = ref(storage, attachment.path);
            await deleteObject(storageRef);
          }
        }

        // Delete the message document
        await deleteDoc(messageRef);

        // Update conversation's last message if needed
        const conversationRef = doc(db, 'conversations', selectedChat);
        const conversationDocSnap = await getDoc(conversationRef);
        
        if (conversationDocSnap.exists() && conversationDocSnap.data().lastMessageId === messageId) {
          // Get the previous message
          const messagesRef = collection(db, 'messages');
          const q = query(
            messagesRef,
            where('conversationId', '==', selectedChat),
            orderBy('timestamp', 'desc'),
            limit(1)
          );
          
          const previousMessages = await getDocs(q);
          const previousMessage = previousMessages.docs[0];
          
          await updateDoc(conversationRef, {
            lastMessageId: previousMessage ? previousMessage.id : null,
            lastMessage: previousMessage ? previousMessage.data().text : '',
            lastMessageTimestamp: previousMessage ? previousMessage.data().timestamp : null
          });
        }
      }
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleSaveEdit = async () => {
    try {
      if (!selectedMessage) return;

      const messageRef = doc(db, 'messages', selectedMessage.id);
      await updateDoc(messageRef, {
        text: newMessage,
        edited: true,
        editedAt: serverTimestamp(),
        editedBy: user.uid
      });

      setIsEditing(false);
      setSelectedMessage(null);
      setNewMessage('');
    } catch (error) {
      console.error('Error saving edited message:', error);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setSelectedMessage(null);
    setNewMessage('');
  };

  const toggleDetailsTile = () => {
    setShowDetailsTile(prev => !prev);
  };

  const getLastMessageDate = () => {
    if (!messages || messages.length === 0) return null;
    const lastMessage = messages[messages.length - 1];
    return lastMessage.timestamp?.toDate();
  };

  const formatDate = (date) => {
    if (!date) return 'No messages';
    
    const now = new Date();
    const messageDate = new Date(date);
    
    // If it's today, show time
    if (messageDate.toDateString() === now.toDateString()) {
      return `Today at ${messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    }
    
    // If it's yesterday
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);
    if (messageDate.toDateString() === yesterday.toDateString()) {
      return `Yesterday at ${messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    }
    
    // If it's this year, show date without year
    if (messageDate.getFullYear() === now.getFullYear()) {
      return messageDate.toLocaleDateString([], { month: 'long', day: 'numeric' });
    }
    
    // If it's a different year, show full date
    return messageDate.toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' });
  };

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredMessages(messages);
    } else {
      const filtered = messages.filter(message => 
        message.text.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredMessages(filtered);
    }
  }, [searchQuery, messages]);

  const handleMuteToggle = async () => {
    if (!selectedChat || !user) return;
    
    try {
      const userRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userRef);
      const userData = userDocSnap.data() || {};
      const mutedConversations = userData.mutedConversations || {};
      
      // Toggle mute status
      const newMutedConversations = {
        ...mutedConversations,
        [selectedChat.id]: !mutedConversations[selectedChat.id]
      };
      
      // Update Firebase
      await updateDoc(userRef, {
        mutedConversations: newMutedConversations
      });
      
      // Update local state
      setIsMuted(!isMuted);
      
      // Show feedback
      toast.success(`Chat ${isMuted ? 'unmuted' : 'muted'} successfully`, {
        duration: 2000,
        position: 'top-right',
        style: {
          background: '#3B82F6',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
        },
        icon: isMuted ? '🔔' : '🔕'
      });
    } catch (error) {
      console.error('Error toggling mute:', error);
      toast.error('Failed to update mute status', {
        duration: 4000,
        position: 'top-right',
        style: {
          background: '#EF4444',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
        },
        icon: '❌'
      });
    }
  };

  useEffect(() => {
    const loadMuteStatus = async () => {
      if (!selectedChat || !user) return;
      
      try {
        const userDocSnap = await getDoc(doc(db, 'users', user.uid));
        const userData = userDocSnap.data() || {};
        const mutedConversations = userData.mutedConversations || {};
        setIsMuted(!!mutedConversations[selectedChat.id]);
      } catch (error) {
        console.error('Error loading mute status:', error);
      }
    };
    
    loadMuteStatus();
  }, [selectedChat, user]);

  // Load pinned chats
  useEffect(() => {
    if (!user?.uid) return;

    const loadPinnedChats = async () => {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setPinnedChats(userData.pinnedChats || []);
        } else {
          setPinnedChats([]);
        }
      } catch (error) {
        console.error('Error loading pinned chats:', error);
        setPinnedChats([]);
      }
    };

    loadPinnedChats();
  }, [user?.uid]);

  const handlePinChat = async (chat) => {
    if (!user?.uid || !chat?.id) return;

    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      const currentPinnedChats = userDoc.data()?.pinnedChats || [];
      
      if (currentPinnedChats.includes(chat.id)) {
        // Remove from pinned chats
        await updateDoc(userRef, {
          pinnedChats: arrayRemove(chat.id)
        });
        toast.success('Chat unpinned', {
          duration: 2000,
          position: 'top-right',
          style: {
            background: '#3B82F6',
            color: '#fff',
            padding: '16px',
            borderRadius: '8px',
          },
          icon: '📌'
        });
      } else {
        // Add to pinned chats
        await updateDoc(userRef, {
          pinnedChats: arrayUnion(chat.id)
        });
        toast.success('Chat pinned', {
          duration: 2000,
          position: 'top-right',
          style: {
            background: '#3B82F6',
            color: '#fff',
            padding: '16px',
            borderRadius: '8px',
          },
          icon: '📌'
        });
      }
    } catch (error) {
      console.error('Error updating pinned chats:', error);
      toast.error('Failed to update pinned chats', {
        duration: 4000,
        position: 'top-right',
        style: {
          background: '#EF4444',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
        },
        icon: '❌'
      });
    }
  };

  useEffect(() => {
    if (!user?.uid) return;

    const userRef = doc(db, 'users', user.uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setPinnedChats(doc.data()?.pinnedChats || []);
      }
    });

    return () => unsubscribe();
  }, [user]);

  // Handle group avatar upload
  const handleGroupAvatarUpload = async (event) => {
    if (!selectedChat?.id || !event.target.files?.[0]) {
      console.log('Missing required data:', { chatId: selectedChat?.id, hasFile: !!event.target.files?.[0] });
      return;
    }
    
    const file = event.target.files[0];
    if (!file.type.startsWith('image/')) {
      toast.error('Please upload an image file', {
        duration: 4000,
        position: 'top-right',
        style: {
          background: '#EF4444',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
        },
        icon: '❌'
      });
      return;
    }
    
    try {
      const storageRef = ref(storage, `groupAvatars/${selectedChat.id}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Error uploading group avatar:', error);
          toast.error('Failed to upload group avatar', {
            duration: 4000,
            position: 'top-right',
            style: {
              background: '#EF4444',
              color: '#fff',
              padding: '16px',
              borderRadius: '8px',
            },
            icon: '❌'
          });
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const chatDocRef = doc(db, 'conversations', selectedChat.id);
            await updateDoc(chatDocRef, {
              groupAvatar: downloadURL
            });
            setGroupAvatarUrl(downloadURL);
            toast.success('Group avatar updated successfully', {
              duration: 2000,
              position: 'top-right',
              style: {
                background: '#3B82F6',
                color: '#fff',
                padding: '16px',
                borderRadius: '8px',
              },
              icon: '📸'
            });
          } catch (error) {
            console.error('Error updating group avatar URL:', error);
            toast.error('Failed to update group avatar', {
              duration: 4000,
              position: 'top-right',
              style: {
                background: '#EF4444',
                color: '#fff',
                padding: '16px',
                borderRadius: '8px',
              },
              icon: '❌'
            });
          }
        }
      );
    } catch (error) {
      console.error('Error handling group avatar upload:', error);
      toast.error('Failed to start upload', {
        duration: 4000,
        position: 'top-right',
        style: {
          background: '#EF4444',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
        },
        icon: '❌'
      });
    }
  };

  // Sort conversations based on pinned status and last message time
  const sortedChats = useMemo(() => {
    return [...conversations].sort((a, b) => {
      // First sort by pinned status
      if (pinnedChats.includes(a.id) && !pinnedChats.includes(b.id)) return -1;
      if (!pinnedChats.includes(a.id) && pinnedChats.includes(b.id)) return 1;
      
      // Then sort by last message time
      const timeA = a.lastMessageTime?.toDate() || a.createdAt?.toDate() || new Date(0);
      const timeB = b.lastMessageTime?.toDate() || b.createdAt?.toDate() || new Date(0);
      return timeB - timeA;
    });
  }, [conversations, pinnedChats]);

  useEffect(() => {
    if (!user?.uid) return;
    
    // Get ended conversations from user's data
    const userRef = doc(db, 'users', user.uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setEndedConversations(doc.data().endedConversations || []);
      }
    });

    return () => unsubscribe();
  }, [user]);

  const handleDeleteChat = async (chat) => {
    if (!user?.uid || !chat?.id) return;

    try {
      const chatRef = doc(db, 'conversations', chat.id);
      const batch = writeBatch(getFirestore());

      // Update conversation to mark it as removed for this user
      batch.update(chatRef, {
        removedBy: arrayUnion(user.uid),
        updatedAt: serverTimestamp()
      });

      // Update user's data to mark conversation as removed
      const userRef = doc(db, 'users', user.uid);
      batch.update(userRef, {
        removedConversations: arrayUnion(chat.id)
      });

      await batch.commit();

      // Clear selected chat if it was the removed one
      if (selectedChat?.id === chat.id) {
        setSelectedChat(null);
      }

      toast.success('Conversation removed from your list', {
        duration: 4000,
        position: 'top-right',
        style: {
          background: '#10B981',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
        },
        icon: '🗑️'
      });
    } catch (error) {
      console.error('Error removing chat:', error);
      toast.error('Failed to remove conversation', {
        duration: 4000,
        position: 'top-right',
        style: {
          background: '#EF4444',
          color: '#fff',
          padding: '16px',
          borderRadius: '8px',
        },
        icon: '❌'
      });
    }
  };

  // Handle conversation selection
  const handleSelectConversation = async (chat) => {
    console.log('Selecting conversation:', chat.id);
    setSelectedChat(chat);
    if (isMobileView) {
      setShowConversationList(false);
    }
    
    try {
      // Mark messages as read for this conversation
      const unreadMessagesQuery = query(
        collection(db, 'messages'),
        where('conversationId', '==', chat.id),
        where('read', '==', false),
        where('senderId', '!=', user.uid)  // Don't mark our own messages as read
      );
      
      const snapshot = await getDocs(unreadMessagesQuery);
      console.log('Found unread messages to mark as read:', snapshot.size);
      
      if (!snapshot.empty) {
        const batch = writeBatch(getFirestore());
        let markedCount = 0;
        
        snapshot.docs.forEach(doc => {
          const messageData = doc.data();
          console.log('Marking message as read:', {
            id: doc.id,
            senderId: messageData.senderId,
            type: messageData.type
          });
          batch.update(doc.ref, { 
            read: true,
            readAt: serverTimestamp()
          });
          markedCount++;
        });
        
        if (markedCount > 0) {
          console.log(`Committing batch update for ${markedCount} messages`);
          await batch.commit();
          console.log('Successfully marked messages as read');
        }
      }

      // Also mark any notifications as acknowledged
      const markNotificationsAcknowledged = async (conversationId) => {
        try {
          console.log('Marking notifications as acknowledged for conversation:', conversationId);
          const notificationsQuery = query(
            collection(db, 'notifications'),
            where('conversationId', '==', conversationId),
            where('userId', '==', user.uid),
            where('acknowledged', '==', false)
          );

          const snapshot = await getDocs(notificationsQuery);
          console.log('Found notifications to acknowledge:', snapshot.size);

          if (!snapshot.empty) {
            const batch = writeBatch(getFirestore());
            snapshot.forEach(doc => {
              console.log('Marking notification as acknowledged:', doc.id);
              batch.update(doc.ref, { 
                acknowledged: true,
                acknowledgedAt: serverTimestamp()
              });
            });

            await batch.commit();
            console.log('Successfully marked notifications as acknowledged');
          }
        } catch (error) {
          console.error('Error marking notifications as acknowledged:', error);
        }
      };

      // Mark notifications as acknowledged when chat is selected
      await markNotificationsAcknowledged(chat.id);
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  // Format roles when messages change
  useEffect(() => {
    const formatRoles = async () => {
      const newRoles = {};
      for (const message of messages) {
        if (message.senderRole && message.senderDepartment) {
          const role = await formatRole(message);
          newRoles[message.id] = role;
        }
      }
      setFormattedRoles(newRoles);
    };

    formatRoles();
  }, [messages]);

  // Function to scroll to bottom of messages
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Effect to scroll to bottom when new messages arrive
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Filter and sort conversations
  const getFilteredConversations = () => {
    console.log('Filtering conversations:', conversations.length);
    const filtered = conversations
      .filter(chat => {
        // Skip removed conversations
        if (chat.removedBy?.includes(user.uid)) {
          console.log('Filtering out removed chat:', chat.id);
          return false;
        }

        // Text search filter
        const matchesSearch = chat.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          getConversationDisplayName(chat).toLowerCase().includes(searchTerm.toLowerCase());
        
        // Message type filter
        if (messageFilter !== 'all') {
          if (messageFilter === 'important' && chat.lastMessagePriority !== 'important') return false;
          if (messageFilter === 'urgent' && chat.lastMessagePriority !== 'urgent') return false;
        }
        
        // Department filter
        if (departmentFilter && chat.department !== departmentFilter) return false;
        
        return matchesSearch;
      })
      .sort((a, b) => {
        // First sort by pinned status
        if (pinnedChats.includes(a.id) && !pinnedChats.includes(b.id)) return -1;
        if (!pinnedChats.includes(a.id) && pinnedChats.includes(b.id)) return 1;

        // Then sort by the selected sort method
        if (sortBy === 'recent') {
          return (b.lastMessageTime?.seconds || b.updatedAt?.seconds || 0) -
                 (a.lastMessageTime?.seconds || a.updatedAt?.seconds || 0);
        }
        if (sortBy === 'priority') {
          const priorityOrder = { urgent: 3, important: 2, normal: 1 };
          return (priorityOrder[b.lastMessagePriority] || 1) - (priorityOrder[a.lastMessagePriority] || 1);
        }
        return 0;
      });
    console.log('Filtered conversations:', filtered.length);
    return filtered;
  };

  // Send message with enhanced properties
  const handleSendMessage = async () => {
    if (!selectedChat || (!newMessage.trim() && selectedFiles.length === 0)) return;

    try {
      setIsUploading(true);
      let attachments = [];

      // Upload files if any
      if (selectedFiles.length > 0) {
        attachments = await uploadFiles();
      }

      const messageData = {
        conversationId: selectedChat.id,
        senderId: user.uid,
        senderName: `${user.firstName} ${user.lastName}`,
        senderRole: user.role,
        senderDepartment: user.department,
        text: newMessage,
        timestamp: serverTimestamp(),
        type: 'normal',
        priority: messageType || 'normal',
        attachments: attachments,
        read: false  // Add read status to new messages
      };

      // Add message to messages collection
      const messageRef = await addDoc(collection(db, 'messages'), messageData);

      // Update conversation with last message info
      await updateDoc(doc(db, 'conversations', selectedChat.id), {
        lastMessage: newMessage || 'Sent attachments',
        lastMessageTime: serverTimestamp(),
        lastMessageId: messageRef.id,
        lastMessageSenderId: user.uid,
        lastMessageType: 'normal',
        lastMessagePriority: messageType || 'normal',
        updatedAt: serverTimestamp()
      });

      // Clear message input and files
      setNewMessage('');
      setMessageType('normal');
      setSelectedFiles([]);
      setUploadProgress({});
      setIsUploading(false);
      
      // Scroll to bottom after sending
      scrollToBottom();
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Error sending message. Please try again.');
      setIsUploading(false);
    }
  };

  // Render chat details section
  const renderChatDetails = () => {
    if (!selectedChat || !showDetailsTile) return null;

    return (
      <div className={`details-tile ${showDetailsTile ? 'show' : ''}`}>
        <div className="details-header">
          <h3>Chat Details</h3>
          <button onClick={() => setShowDetailsTile(false)} className="close-details-btn">
            <span>×</span>
          </button>
        </div>
        
        <div className="details-content">
          <div className="details-search">
            <div className="search-input">
              <i className="fas fa-search"></i>
              <input
                type="text"
                placeholder="Search in conversation..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {searchQuery && (
                <button 
                  className="clear-search"
                  onClick={() => setSearchQuery('')}
                >
                  <i className="fas fa-times"></i>
                </button>
              )}
            </div>
          </div>

          <div className="details-actions">
            <button 
              className={`pin-chat-button ${isMuted ? 'muted' : ''}`}
              onClick={handleMuteToggle}
            >
              <i className={`fas fa-${isMuted ? 'bell-slash' : 'bell'}`}></i>
              {isMuted ? 'Unmute' : 'Mute'} Notifications
            </button>
            <button 
              className="pin-chat-button"
              onClick={() => handlePinChat(selectedChat)}
            >
              <i className="fas fa-thumbtack"></i>
              {pinnedChats.includes(selectedChat.id) ? 'Unpin Chat' : 'Pin Chat'}
            </button>
            <button
              className="pin-chat-button"
              onClick={() => {
                if (window.confirm('Are you sure you want to remove this conversation from your list? Other participants will be notified.')) {
                  handleDeleteChat(selectedChat);
                }
              }}
            >
              <i className="fas fa-trash-alt"></i>
              Remove Conversation
            </button>
          </div>

          <div className="details-section">
            <h4>Participants</h4>
            <div className="participants-list">
              {chatParticipants.map(participant => (
                <div key={participant.id} className="participant-item">
                  {participant.photoURL ? (
                    <img 
                      src={participant.photoURL} 
                      alt={participant.displayName} 
                      className="participant-avatar"
                    />
                  ) : (
                    <div className="participant-avatar">
                      {getInitials(participant.firstName, participant.lastName)}
                    </div>
                  )}
                  <div className="participant-info">
                    <div className="participant-name">{participant.name}</div>
                    <div className="participant-department">{participant.departmentName}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {selectedChat.type === 'department' && (
            <div className="details-section">
              <h4>Department</h4>
              <p>{getDepartmentName(selectedChat.department)}</p>
            </div>
          )}

          <div className="details-section">
            <h4>Last Message</h4>
            <p>{formatDate(getLastMessageDate())}</p>
          </div>

          {selectedChat && selectedChat.type === 'group' && (
            <div className="group-avatar-section">
              <h4>Group Picture</h4>
              <div className="group-avatar-container">
                {groupAvatarUrl || selectedChat.groupAvatar ? (
                  <img 
                    src={groupAvatarUrl || selectedChat.groupAvatar}
                    alt="Group avatar" 
                    className="group-avatar"
                  />
                ) : (
                  <div className="group-avatar-placeholder">
                    <i className="fas fa-users"></i>
                  </div>
                )}
                <button 
                  className="change-avatar-button"
                  onClick={() => groupAvatarRef.current.click()}
                >
                  Change Picture
                </button>
                <input
                  type="file"
                  ref={groupAvatarRef}
                  onChange={handleGroupAvatarUpload}
                  accept="image/*"
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderMessage = (message) => {
    const isCurrentUser = message.senderId === user.uid;
    const messageClass = isCurrentUser ? 'sent' : 'received';
    const priorityClass = message.priority ? ` ${message.priority}` : '';

    return (
      <div key={message.id} className={`message-item ${messageClass}`}>
        <div className="message-content">
          <div className="message-sender-info">
            <span className="sender-name">{message.senderName}</span>
            {formattedRoles[message.id] && (
              <span className="sender-role">{formattedRoles[message.id]}</span>
            )}
          </div>
          <div 
            className={`message-text${priorityClass}`}
            dangerouslySetInnerHTML={{ __html: message.text }}
          />
          {message.attachments && message.attachments.length > 0 && (
            <div className="message-attachments">
              {message.attachments.map((attachment, index) => {
                const isImage = attachment.fileType?.startsWith('image/');
                return (
                  <div key={index} className="attachment-item">
                    {isImage ? (
                      <div className="image-attachment">
                        <img 
                          src={attachment.url} 
                          alt={attachment.fileName}
                          onClick={() => window.open(attachment.url, '_blank')}
                        />
                      </div>
                    ) : (
                      <div 
                        className="file-attachment"
                        onClick={() => window.open(attachment.url, '_blank')}
                      >
                        <i className="fas fa-file"></i>
                        <span className="file-name">{attachment.fileName}</span>
                        <span className="file-size">({formatFileSize(attachment.fileSize)})</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <span className="message-time">
            {message.timestamp?.toDate().toLocaleTimeString()}
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };
    
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="page-container">
      <Sidebar />
      <div className="content-wrapper">
        <div className="messages-layout">
          <div className="group-navigation">
            <div className="group-nav-content">
              <div 
                className={`group-nav-item ${messageFilter === 'all' ? 'active' : ''}`}
                onClick={() => setMessageFilter('all')}
              >
                <i className="fas fa-comments"></i>
                <span className="group-nav-tooltip">All Chats</span>
              </div>
              <div 
                className={`group-nav-item ${messageFilter === 'important' ? 'active' : ''}`}
                onClick={() => setMessageFilter('important')}
              >
                <i className="fas fa-exclamation-circle"></i>
                <span className="group-nav-tooltip">Important</span>
              </div>
              <div 
                className={`group-nav-item ${messageFilter === 'urgent' ? 'active' : ''}`}
                onClick={() => setMessageFilter('urgent')}
              >
                <i className="fas fa-exclamation-triangle"></i>
                <span className="group-nav-tooltip">Urgent</span>
              </div>
              <div className="group-nav-divider"></div>
              <div 
                className={`group-nav-item ${sortBy === 'recent' ? 'active' : ''}`}
                onClick={() => setSortBy('recent')}
              >
                <i className="fas fa-clock"></i>
                <span className="group-nav-tooltip">Sort by Recent</span>
              </div>
              <div 
                className={`group-nav-item ${sortBy === 'priority' ? 'active' : ''}`}
                onClick={() => setSortBy('priority')}
              >
                <i className="fas fa-sort-amount-down"></i>
                <span className="group-nav-tooltip">Sort by Priority</span>
              </div>
              <div className="group-nav-item" onClick={toggleDetailsTile}>
                <i className="fas fa-info-circle"></i>
                <span className="group-nav-tooltip">Toggle Details</span>
              </div>
            </div>
          </div>

          <div className="messages-container">
            <div className={`left-panel ${!showConversationList ? 'hidden' : ''}`}>
              <div className="chat-list-header">
                <h2>Messages</h2>
                <button 
                  className="new-chat-button"
                  onClick={handleNewChat}
                >
                  <i className="fas fa-plus"></i>
                  New Chat
                </button>
              </div>

              <div className="search-filters">
                <div className="search-bar">
                  <input
                    type="text"
                    placeholder="Search conversations..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <select
                  className="department-filter"
                  value={departmentFilter}
                  onChange={(e) => setDepartmentFilter(e.target.value)}
                >
                  <option value="">All Departments</option>
                  {getVisibleDepartments().map(dept => (
                    <option key={dept.id} value={dept.id}>
                      {dept.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="conversations-list">
                {getFilteredConversations().map(chat => (
                  <div 
                    key={chat.id}
                    className={`conversation-item ${selectedChat?.id === chat.id ? 'selected' : ''}`}
                    onClick={(e) => {
                      console.log('Clicked conversation:', chat.id);
                      e.stopPropagation();
                      handleSelectConversation(chat);
                    }}
                  >
                    <ConversationItem
                      chat={chat}
                      selectedChat={selectedChat}
                      pinnedChats={pinnedChats}
                      endedConversations={endedConversations}
                      getParticipantDisplayInfo={getParticipantDisplayInfo}
                      getConversationDisplayName={getConversationDisplayName}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className={`chat-area-container ${!showConversationList ? 'visible' : ''}`}>
              {selectedChat ? (
                <>
                  <div className="chat-header">
                    <button 
                      className="back-button"
                      onClick={() => {
                        if (isMobileView) {
                          setShowConversationList(true);
                          setSelectedChat(null);
                        }
                      }}
                    >
                      <i className="fas fa-arrow-left"></i>
                    </button>
                    <div className="chat-header-info">
                      <h3>{selectedChat?.name}</h3>
                      <span className="chat-status">
                        {selectedChat?.participants?.length} participants
                      </span>
                    </div>
                    <div className="chat-header-actions">
                      <div 
                        className="search-icon"
                        onClick={() => setIsSearchVisible(!isSearchVisible)}
                      >
                        <i className="fas fa-search"></i>
                      </div>
                      <button className="icon-button">
                        <i className="fas fa-phone"></i>
                      </button>
                      <button className="icon-button">
                        <i className="fas fa-video"></i>
                      </button>
                      <button 
                        className="icon-button"
                        onClick={() => setShowDetailsTile(!showDetailsTile)}
                      >
                        <i className="fas fa-info-circle"></i>
                      </button>
                    </div>
                    <div className={`mobile-search-input ${isSearchVisible ? 'active' : ''}`}>
                      <input
                        type="text"
                        placeholder="Search in conversation..."
                        onChange={(e) => {/* Add your search logic here */}}
                      />
                      <div 
                        className="close-search"
                        onClick={() => setIsSearchVisible(false)}
                      >
                        <i className="fas fa-times"></i>
                      </div>
                    </div>
                  </div>

                  <div className="messages-list">
                    {filteredMessages.map(message => renderMessage(message))}
                    <div ref={messagesEndRef} />
                  </div>

                  <div className="message-input-container">
                    {selectedFiles.length > 0 && (
                      <div className="selected-files">
                        {selectedFiles.map((file, index) => (
                          <div key={index} className="selected-file">
                            <span>{file.name} ({formatFileSize(file.size)})</span>
                            <button 
                              onClick={() => handleRemoveFile(index)}
                              className="remove-file"
                            >
                              ×
                            </button>
                            {uploadProgress[index] !== undefined && (
                              <span className="upload-progress">
                                Uploading... ({uploadProgress[index]}%)
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    {fileErrors.length > 0 && (
                      <div className="file-errors">
                        {fileErrors.map((error, index) => (
                          <div key={index} className="error-message">
                            {error}
                          </div>
                        ))}
                      </div>
                    )}
                    {isEditing ? (
                      <div className="edit-message-container">
                        <ReactQuill
                          value={newMessage}
                          onChange={setNewMessage}
                          modules={modules}
                          formats={formats}
                          placeholder="Edit your message..."
                          theme="snow"
                        />
                        <div className="edit-actions">
                          <button onClick={handleSaveEdit} className="save-edit-btn">
                            Save Changes
                          </button>
                          <button onClick={handleCancelEdit} className="cancel-edit-btn">
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <ReactQuill
                        value={newMessage}
                        onChange={setNewMessage}
                        modules={modules}
                        formats={formats}
                        placeholder="Type your message..."
                        theme="snow"
                      />
                    )}
                    <div className="message-actions">
                      <button
                        type="button"
                        onClick={() => fileInputRef.current?.click()}
                        className="attach-button"
                        disabled={isUploading}
                      >
                        <i className="fas fa-paperclip"></i>
                      </button>
                      <button
                        type="button"
                        onClick={handleSendMessage}
                        className="send-button"
                        disabled={isUploading || (!newMessage.trim() && selectedFiles.length === 0)}
                      >
                        {isUploading ? (
                          <i className="fas fa-spinner fa-spin"></i>
                        ) : (
                          <i className="fas fa-paper-plane"></i>
                        )}
                      </button>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                      accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/zip, video/mp4, audio/mpeg"
                      multiple
                      style={{ display: 'none' }}
                    />
                  </div>
                </>
              ) : (
                <div className="no-chat-selected">
                  <h2>Select a conversation to start messaging</h2>
                </div>
              )}
            </div>

            {renderChatDetails()}
          </div>
        </div>
      </div>

      <ChatModal
        isOpen={showNewChatModal}
        onClose={() => setShowNewChatModal(false)}
        onCreateChat={handleCreateChat}
        user={user}
      />
    </div>
  );
}

export default Messages;