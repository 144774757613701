import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { format } from 'date-fns';
import { useAuth } from '../../hooks/useAuth';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ProductionSubscriptionDashboard = ({ productionId }) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { user } = useAuth();
  const productionName = user?.production;

  const handleSubscribe = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to load');

      // Get the Firebase Functions URL from environment variable or construct it
      const functionsBaseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL || 
        'https://us-central1-your-project-id.cloudfunctions.net';

      const response = await fetch(`${functionsBaseUrl}/createCheckoutSession`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productionId: productionId,
          productionName: productionName,
          userId: user.uid
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const session = await response.json();
      
      if (!session.id) {
        throw new Error('Invalid session response');
      }

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error in subscription process:', error);
      setError('Failed to process subscription. Please try again later.');
    }
  };

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        setLoading(true);
        setError(null);

        // First try to find the subscription by production name
        const subsQuery = query(
          collection(db, 'subscriptions'),
          where('productionName', '==', productionName)
        );
        const subsQuerySnapshot = await getDocs(subsQuery);
        
        let subscriptionDoc = null;
        if (!subsQuerySnapshot.empty) {
          subscriptionDoc = subsQuerySnapshot.docs[0];
        } else if (productionId) {
          // Fallback to productionId if available
          const subscriptionRef = doc(db, 'subscriptions', productionId);
          const subscriptionSnap = await getDoc(subscriptionRef);
          if (subscriptionSnap.exists()) {
            subscriptionDoc = subscriptionSnap;
          }
        }

        if (subscriptionDoc) {
          const data = subscriptionDoc.data();
          setSubscriptionData({
            ...data,
            trialEndDate: data.trialEndDate?.toDate(),
            createdAt: data.createdAt?.toDate(),
            updatedAt: data.updatedAt?.toDate()
          });
        } else {
          // If no subscription found, create a default trial subscription
          const defaultSubscription = {
            status: 'trial',
            trialEndDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 days from now
            createdAt: new Date(),
            updatedAt: new Date(),
            productionName: productionName,
            features: {
              maxUsers: 50,
              maxStorage: '100GB',
              supportLevel: 'standard'
            }
          };
          setSubscriptionData(defaultSubscription);
        }
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        setError('Failed to load subscription information. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (productionName || productionId) {
      fetchSubscriptionData();
    }
  }, [productionId, productionName]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'trial':
        return '#3498db';
      case 'active':
        return '#2ecc71';
      case 'expired':
        return '#e74c3c';
      default:
        return '#95a5a6';
    }
  };

  const getRemainingTrialDays = () => {
    if (!subscriptionData?.trialEndDate) return 0;
    const now = new Date();
    const diffTime = subscriptionData.trialEndDate - now;
    return Math.max(0, Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
  };

  if (loading) {
    return (
      <div className="subscription-dashboard loading">
        <h2>Loading subscription information...</h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className="subscription-dashboard error">
        <h2>Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  if (!subscriptionData) {
    return (
      <div className="subscription-dashboard no-data">
        <h2>Production Dashboard</h2>
        <p>Welcome to {productionName || 'your production'}</p>
        <div className="dashboard-content">
          <div className="info-card">
            <h3>Getting Started</h3>
            <p>Your production is being set up. Please contact support if you need assistance.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="subscription-dashboard">
      <div className="subscription-header" onClick={() => setIsCollapsed(!isCollapsed)}>
        <h2>Subscription Status</h2>
        <button className="collapse-button">
          {isCollapsed ? '▼' : '▲'}
        </button>
      </div>
      {!isCollapsed && (
        <div className="dashboard-content">
          <div className="subscription-status" style={{ backgroundColor: getStatusColor(subscriptionData.status) }}>
            <h3>Current Plan</h3>
            <p>{subscriptionData.status.toUpperCase()}</p>
            {subscriptionData.status === 'trial' && (
              <>
                <p>Trial ends in {getRemainingTrialDays()} days</p>
                <button 
                  className="subscribe-button"
                  onClick={handleSubscribe}
                >
                  Upgrade to Premium
                </button>
              </>
            )}
          </div>

          <div className="features-section">
            <h3>Features</h3>
            <div className="feature-grid">
              <div className="feature-item">
                <h4>Users</h4>
                <p>Max: {subscriptionData.features?.maxUsers || 'Unlimited'}</p>
              </div>
              <div className="feature-item">
                <h4>Storage</h4>
                <p>{subscriptionData.features?.maxStorage || '100GB'}</p>
              </div>
              <div className="feature-item">
                <h4>Support</h4>
                <p>{subscriptionData.features?.supportLevel || 'Standard'}</p>
              </div>
            </div>
          </div>

          {subscriptionData.status !== 'trial' && (
            <div className="payment-section">
              <h3>Payment Information</h3>
              <div className="payment-details">
                <p>Next billing date: {subscriptionData.nextBillingDate ? 
                  format(new Date(subscriptionData.nextBillingDate), 'PPP') : 'N/A'}</p>
                <p>Plan: {subscriptionData.planName || 'Premium'}</p>
                <p>Amount: ${subscriptionData.amount || '49.99'}/month</p>
              </div>
              <button 
                className="manage-subscription-button"
                onClick={() => window.location.href = '/manage-subscription'}
              >
                Manage Subscription
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductionSubscriptionDashboard;
