import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  getAuth, 
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut
} from 'firebase/auth';
import { 
  doc, 
  getDoc, 
  setDoc,
  updateDoc,
  increment,
  serverTimestamp 
} from 'firebase/firestore';
import { auth, db } from '../firebase';
import { ROLES } from '../utils/permissions';

// Role mapping for normalization
const ROLE_MAPPING = {
  'supervisor': ROLES.PRODUCTION_SUPERVISOR,
  'Supervisor': ROLES.PRODUCTION_SUPERVISOR,
  'admin': ROLES.ADMIN,
  'Admin': ROLES.ADMIN,
  'super_admin': ROLES.SUPER_ADMIN,
  'superadmin': ROLES.SUPER_ADMIN,
  'SuperAdmin': ROLES.SUPER_ADMIN,
  'production_admin': ROLES.PRODUCTION_ADMIN,
  'productionadmin': ROLES.PRODUCTION_ADMIN,
  'ProductionAdmin': ROLES.PRODUCTION_ADMIN,
  'crew_member': ROLES.CREW_MEMBER,
  'crewmember': ROLES.CREW_MEMBER,
  'CrewMember': ROLES.CREW_MEMBER,
  'department_head': ROLES.DEPARTMENT_HEAD,
  'departmenthead': ROLES.DEPARTMENT_HEAD,
  'DepartmentHead': ROLES.DEPARTMENT_HEAD
};

// Function to normalize role
const normalizeRole = (role) => {
  if (!role) return ROLES.CREW_MEMBER; // Default role
  const normalizedRole = ROLE_MAPPING[role] || role;
  console.log('Normalizing role:', role, 'to:', normalizedRole);
  return normalizedRole;
};

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleSignOut = async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        try {
          // Fetch additional user data including role from Firestore
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          const userData = userDoc.data();
          
          if (!userData) {
            // If no user data exists, create a default user document
            const defaultUserData = {
              email: firebaseUser.email,
              role: ROLES.CREW_MEMBER,
              createdAt: serverTimestamp(),
              lastLogin: serverTimestamp(),
              loginCount: 1
            };
            
            await setDoc(doc(db, 'users', firebaseUser.uid), defaultUserData);
            
            setUser({
              ...firebaseUser,
              ...defaultUserData,
              role: ROLES.CREW_MEMBER
            });
          } else {
            // Update login count and last login
            await updateDoc(doc(db, 'users', firebaseUser.uid), {
              lastLogin: serverTimestamp(),
              loginCount: increment(1)
            });

            setUser({
              ...firebaseUser,
              ...userData,
              role: normalizeRole(userData.role)
            });
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          // Set basic user data if there's an error
          setUser({
            ...firebaseUser,
            role: ROLES.CREW_MEMBER
          });
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const value = {
    user,
    setUser,
    loading,
    signOut: handleSignOut
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;