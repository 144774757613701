import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './ChatModal.css';

const ChatModal = ({ isOpen, onClose, onCreateChat, user }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [crewMembers, setCrewMembers] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState({});

  useEffect(() => {
    if (isOpen) {
      fetchUsers();
      fetchDepartments();
    }
  }, [isOpen]);

  const fetchUsers = async () => {
    if (!user) return;

    try {
      setLoading(true);
      
      // Get current user's data for context
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();

      if (!userData?.production) return;

      const usersCollection = collection(db, 'users');
      let usersQuery;

      const isProductionAdmin = userData.role?.toLowerCase().includes('admin');
      const isSupervisor = userData.role?.toLowerCase().includes('supervisor');

      // Build query based on user role
      if (isProductionAdmin) {
        usersQuery = query(usersCollection,
          where('production', '==', userData.production)
        );
      } else if (isSupervisor || userData.department) {
        usersQuery = query(usersCollection,
          where('production', '==', userData.production),
          where('department', '==', userData.department)
        );
      } else {
        usersQuery = query(usersCollection,
          where('production', '==', userData.production)
        );
      }

      const querySnapshot = await getDocs(usersQuery);
      const allUsers = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Split users into crew members and supervisors
      const crewList = allUsers.filter(user => 
        user.role?.toLowerCase().includes('crew') || 
        user.role?.toLowerCase().includes('member')
      );
      
      const supervisorList = allUsers.filter(user => 
        user.role?.toLowerCase().includes('supervisor')
      );

      setCrewMembers(crewList);
      setSupervisors(supervisorList);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDepartments = async () => {
    try {
      const departmentsSnapshot = await getDocs(collection(db, 'departments'));
      const departmentsData = {};
      departmentsSnapshot.forEach(doc => {
        const data = doc.data();
        departmentsData[doc.id] = data.name;
      });
      setDepartments(departmentsData);
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const getInitials = (name) => {
    if (!name) return '??';
    const names = name.split(' ');
    if (names.length >= 2) {
      return (names[0].charAt(0) + names[names.length - 1].charAt(0)).toUpperCase();
    }
    return name.charAt(0).toUpperCase();
  };

  const getDepartmentName = (departmentId) => {
    return departments[departmentId] || departmentId;
  };

  const handleUserSelect = (userId) => {
    setSelectedUsers(prev => {
      if (prev.includes(userId)) {
        return prev.filter(id => id !== userId);
      }
      return [...prev, userId];
    });
  };

  const handleCreateChat = async () => {
    console.log('Create chat button clicked');
    console.log('Selected users:', selectedUsers);
    
    if (selectedUsers.length === 0) {
      alert('Please select at least one user');
      return;
    }

    setLoading(true);
    try {
      // Get selected users' full data
      const selectedUsersData = {};
      const selectedUsersFullData = [...crewMembers, ...supervisors].filter(u => selectedUsers.includes(u.id));
      
      selectedUsersFullData.forEach(member => {
        selectedUsersData[member.id] = {
          firstName: member.firstName || '',
          lastName: member.lastName || '',
          role: member.role || '',
          department: member.department || '',
          title: member.title || '',
          email: member.email || '',
          name: member.name || '',
          displayName: `${member.firstName || ''} ${member.lastName || ''}`.trim() || member.name || 'Unknown User'
        };
      });

      console.log('Current user:', user);
      console.log('Selected users data:', selectedUsersData);
      
      const chatDetails = {
        type: selectedUsers.length === 1 ? 'direct' : 'group',
        recipients: selectedUsers,
        recipientsData: selectedUsersData,
        name: selectedUsers.length === 1 ? '' : `Group Chat (${selectedUsers.length + 1} members)`,
        priority: 'normal',
        accessLevel: 'all',
        department: user?.department || '',
        description: '',
        productionId: user?.production
      };
      
      console.log('Attempting to create chat with details:', chatDetails);
      await onCreateChat(chatDetails);
      console.log('Chat created successfully');
      handleClose();
    } catch (error) {
      console.error('Detailed error creating chat:', error);
      alert('Failed to create chat. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSelectedUsers([]);
    setSearchTerm('');
    onClose();
  };

  if (!isOpen) return null;

  const filteredCrewMembers = crewMembers.filter(member =>
    member.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    getDepartmentName(member.department)?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredSupervisors = supervisors.filter(supervisor =>
    supervisor.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    getDepartmentName(supervisor.department)?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="chat-modal-overlay">
      <div className="chat-modal">
        <div className="chat-modal-header">
          <h2>New Chat</h2>
          <button 
            onClick={handleClose}
            className="close-button"
            aria-label="Close modal"
          >
            ×
          </button>
        </div>

        <div className="chat-modal-content">
          <div className="search-box">
            <input
              type="text"
              placeholder="Search by name or department..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>

          <div className="users-section">
            <div className="user-group">
              <h3>Crew Members</h3>
              <div className="users-grid">
                {filteredCrewMembers.map(member => (
                  <div 
                    key={member.id}
                    className={`user-tile ${selectedUsers.includes(member.id) ? 'selected' : ''}`}
                    onClick={() => handleUserSelect(member.id)}
                  >
                    <div className="user-avatar">
                      {member.photoURL ? (
                        <img 
                          src={member.photoURL} 
                          alt={`${member.name}'s profile`}
                          className="profile-image"
                        />
                      ) : (
                        <div className="profile-initial">
                          {getInitials(member.name)}
                        </div>
                      )}
                    </div>
                    <div className="user-info">
                      <div className="user-name">{member.name}</div>
                      <div className="user-department">{getDepartmentName(member.department)}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {supervisors.length > 0 && (
              <div className="user-group">
                <h3>Supervisors</h3>
                <div className="users-grid">
                  {filteredSupervisors.map(supervisor => (
                    <div 
                      key={supervisor.id}
                      className={`user-tile ${selectedUsers.includes(supervisor.id) ? 'selected' : ''}`}
                      onClick={() => handleUserSelect(supervisor.id)}
                    >
                      <div className="user-avatar">
                        {supervisor.photoURL ? (
                          <img 
                            src={supervisor.photoURL} 
                            alt={`${supervisor.name}'s profile`}
                            className="profile-image"
                          />
                        ) : (
                          <div className="profile-initial">
                            {getInitials(supervisor.name)}
                          </div>
                        )}
                      </div>
                      <div className="user-info">
                        <div className="user-name">{supervisor.name}</div>
                        <div className="user-department">{getDepartmentName(supervisor.department)}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="chat-modal-footer">
          <button 
            onClick={handleClose}
            className="cancel-button"
            type="button"
          >
            Cancel
          </button>
          <button
            onClick={handleCreateChat}
            className="create-button"
            disabled={selectedUsers.length === 0 || loading}
            type="button"
          >
            {loading ? 'Creating...' : 'Create Chat'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatModal;
