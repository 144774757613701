import React, { useState, useContext, useEffect } from 'react';
import { promoteUser } from '../services/productions';
import { AuthContext } from '../contexts/AuthContext';
import { ROLES, PERMISSIONS, ROLE_PERMISSIONS } from '../constants/roles';
import { db } from '../firebase';
import { collection, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { deleteUser, getAuth } from 'firebase/auth';
import '../styles/Management.css';

const UserManagement = () => {
    const { user } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            if (!user?.uid) return;

            try {
                setLoading(true);
                const usersSnapshot = await getDocs(collection(db, 'users'));
                const usersData = usersSnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                    .filter(u => u.id !== user?.uid);
                setUsers(usersData);
            } catch (error) {
                console.error('Error fetching users:', error);
                setError('Failed to fetch users');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [user?.uid]);

    // Check if user exists
    if (!user) {
        return <div className="alert alert-warning">Please log in to access this page.</div>;
    }

    // Check permissions
    const canPromoteToAdmin = user?.role && 
        ROLE_PERMISSIONS[user.role]?.includes(PERMISSIONS.PROMOTE_TO_ADMIN);
    const canPromoteToSuperAdmin = user?.role && 
        ROLE_PERMISSIONS[user.role]?.includes(PERMISSIONS.PROMOTE_TO_SUPER_ADMIN);

    if (!canPromoteToAdmin && !canPromoteToSuperAdmin) {
        return <div className="alert alert-warning">You do not have permission to manage user roles.</div>;
    }

    const handlePromote = async (e) => {
        e.preventDefault();
        if (!user?.uid) return; // Add this check

        setError('');
        setSuccess('');
        setLoading(true);

        try {
            if (!selectedUser || !selectedRole) {
                throw new Error('Please select both a user and a role');
            }

            // Check permissions based on selected role
            if (selectedRole === ROLES.SUPER_ADMIN && !canPromoteToSuperAdmin) {
                throw new Error('You do not have permission to promote to Super Admin');
            }
            if (selectedRole === ROLES.ADMIN && !canPromoteToAdmin) {
                throw new Error('You do not have permission to promote to Admin');
            }

            await promoteUser(user.uid, selectedUser, selectedRole);
            setSuccess(`User successfully promoted to ${selectedRole}`);
            
            // Reset form
            setSelectedUser('');
            setSelectedRole('');

            // Refresh user list
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const usersData = usersSnapshot.docs
                .map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }))
                .filter(u => u.id !== user.uid);
            setUsers(usersData);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteUser = async (userId, userEmail) => {
        if (!window.confirm(`Are you sure you want to delete user ${userEmail}? This action cannot be undone.`)) {
            return;
        }

        setIsDeletingUser(true);
        try {
            // Delete from Firestore
            await deleteDoc(doc(db, 'users', userId));
            
            // Delete from Authentication
            const auth = getAuth();
            // Note: Deleting from Auth requires admin SDK or a Cloud Function
            // This is a security measure by Firebase
            
            setSuccess(`User ${userEmail} has been deleted`);
            // Refresh user list
            const updatedUsers = users.filter(u => u.id !== userId);
            setUsers(updatedUsers);
        } catch (error) {
            console.error('Error deleting user:', error);
            setError(`Failed to delete user: ${error.message}`);
        } finally {
            setIsDeletingUser(false);
        }
    };

    return (
        <div className="user-management">
            <h2>Manage User Roles</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            
            <form onSubmit={handlePromote}>
                <div className="form-group">
                    <label>Select User</label>
                    <select
                        className="form-control"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                        required
                    >
                        <option value="">Select a user...</option>
                        {users.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.email} (Current role: {user.role})
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label>Select New Role</label>
                    <select
                        className="form-control"
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                        required
                    >
                        <option value="">Select a role...</option>
                        <option value="Crew Member">Crew Member</option>
                        <option value="Supervisor">Supervisor</option>
                        <option value="Production Admin">Production Admin</option>
                        <option value="Admin">Admin</option>
                        {canPromoteToSuperAdmin && (
                            <option value="Super Admin">Super Admin</option>
                        )}
                    </select>
                </div>

                <div className="form-group">
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? 'Promoting...' : 'Promote User'}
                    </button>
                </div>
            </form>

            <div className="user-list">
                <h3>Current Users</h3>
                {users.map(u => (
                    <div key={u.id} className="user-card">
                        <div className="user-info">
                            <div className="user-name">
                                {u.name || `${u.firstName || ''} ${u.lastName || ''}`}
                            </div>
                            <div className="user-email">{u.email}</div>
                            <span className="user-role">{u.role}</span>
                            <span className="login-count">
                                Logins: {u.loginCount || 0}
                            </span>
                            <span className="last-login">
                                Last Login: {u.lastLogin ? new Date(u.lastLogin).toLocaleString() : 'Never'}
                            </span>
                        </div>
                        <div className="user-actions">
                            <button
                                className="btn btn-danger"
                                onClick={() => handleDeleteUser(u.id, u.email)}
                                disabled={isDeletingUser}
                            >
                                {isDeletingUser ? 'Deleting...' : 'Delete User'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserManagement;
