import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import './styles/ProductionAdminDashboard.css';

const ProductionAdminDashboard = ({ productionId }) => {
  const [productionStats, setProductionStats] = useState({
    totalCrewMembers: 0,
    activeShifts: 0,
    pendingApprovals: 0
  });

  useEffect(() => {
    const fetchProductionStats = async () => {
      if (!productionId) return;

      try {
        const crewRef = collection(db, 'crewMembers');
        const shiftsRef = collection(db, 'shifts');
        const approvalsRef = collection(db, 'approvals');

        const crewQuery = query(crewRef, where('productionId', '==', productionId));
        const shiftsQuery = query(shiftsRef, 
          where('productionId', '==', productionId),
          where('status', '==', 'active')
        );
        const approvalsQuery = query(approvalsRef,
          where('productionId', '==', productionId),
          where('status', '==', 'pending')
        );

        const [crewSnap, shiftsSnap, approvalsSnap] = await Promise.all([
          getDocs(crewQuery),
          getDocs(shiftsQuery),
          getDocs(approvalsQuery)
        ]);

        setProductionStats({
          totalCrewMembers: crewSnap.size,
          activeShifts: shiftsSnap.size,
          pendingApprovals: approvalsSnap.size
        });
      } catch (error) {
        console.error('Error fetching production statistics:', error);
      }
    };

    fetchProductionStats();
  }, [productionId]);

  return (
    <div className="production-admin-dashboard">
      <h1>Production Administration Dashboard</h1>
      
      {/* Production Stats Section */}
      <div className="stats-container">
        <div className="stat-card">
          <h3>Total Crew Members</h3>
          <p>{productionStats.totalCrewMembers}</p>
        </div>
        <div className="stat-card">
          <h3>Active Shifts</h3>
          <p>{productionStats.activeShifts}</p>
        </div>
        <div className="stat-card">
          <h3>Pending Approvals</h3>
          <p>{productionStats.pendingApprovals}</p>
        </div>
      </div>

      {/* Additional Production Management Sections */}
      <div className="production-admin-grid">
        <section className="dashboard-section">
          <h2>Crew Management</h2>
          <div className="management-content">
            {/* Add crew management components */}
          </div>
        </section>

        <section className="dashboard-section">
          <h2>Schedule Management</h2>
          <div className="management-content">
            {/* Add schedule management components */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProductionAdminDashboard;
