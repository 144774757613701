import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import Sidebar from '../components/Sidebar';
import Toast from '../components/Toast';
import './Settings.css';

const calculateExpirationTime = (duration) => {
  const now = new Date();
  switch (duration) {
    case '6h': return now.getTime() + (6 * 60 * 60 * 1000);
    case '12h': return now.getTime() + (12 * 60 * 60 * 1000);
    case '24h': return now.getTime() + (24 * 60 * 60 * 1000);
    case '48h': return now.getTime() + (48 * 60 * 60 * 1000);
    case '5d': return now.getTime() + (5 * 24 * 60 * 60 * 1000);
    case '7d': return now.getTime() + (7 * 24 * 60 * 60 * 1000);
    default: return now.getTime() + (6 * 60 * 60 * 1000);
  }
};

function Settings() {
  const { user, updatePassword } = useAuth();
  const [settings, setSettings] = useState({
    emailNotifications: user?.emailNotifications ?? true,
    pushNotifications: user?.pushNotifications ?? true,
    darkMode: user?.darkMode ?? false,
    language: user?.language || 'English',
    timeZone: user?.timeZone || 'UTC',
    sessionDuration: user?.sessionDuration || '6h'
  });

  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  const [toast, setToast] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const sessionOptions = [
    { value: '6h', label: '6 Hours' },
    { value: '12h', label: '12 Hours' },
    { value: '24h', label: '24 Hours' },
    { value: '48h', label: '48 Hours' },
    { value: '5d', label: '5 Days' },
    { value: '7d', label: '1 Week' }
  ];

  const handleSettingChange = async (setting, value) => {
    setSettings(prev => ({
      ...prev,
      [setting]: value
    }));

    if (setting === 'sessionDuration') {
      try {
        const db = getFirestore();
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          sessionDuration: value
        });

        const expirationTime = calculateExpirationTime(value);
        localStorage.setItem('sessionExpiration', expirationTime.toString());

        setToast({
          message: 'Session duration updated successfully!',
          type: 'success'
        });
      } catch (error) {
        console.error('Error updating session duration:', error);
        setToast({
          message: 'Failed to update session duration',
          type: 'error'
        });
      }
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords(prev => ({
      ...prev,
      [name]: value
    }));
  };

    const handlePasswordReset = async () => {
    if (passwords.newPassword !== passwords.confirmPassword) {
      setToast({
        message: 'Passwords do not match.',
        type: 'error',
      });
      return;
    }

    setIsLoading(true);
    try {
        await updatePassword(passwords.newPassword);
      setToast({
        message: 'Password changed successfully!',
        type: 'success',
      });
    } catch (error) {
      console.error("Error updating password:", error);
      setToast({
        message: 'Failed to update password.',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }

      // Reset password fields
      setPasswords({ newPassword: '', confirmPassword: '' });
  };

  const handleSaveSettings = async (e) => {
      e.preventDefault();
    setIsLoading(true);

    try {
      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);

      await updateDoc(userRef, {
        emailNotifications: settings.emailNotifications,
        pushNotifications: settings.pushNotifications,
        darkMode: settings.darkMode,
        language: settings.language,
        timeZone: settings.timeZone,
        sessionDuration: settings.sessionDuration,
        lastUpdate: new Date().toISOString()
      });

      // Update session expiration if duration changed
      const expirationTime = calculateExpirationTime(settings.sessionDuration);
      localStorage.setItem('sessionExpiration', expirationTime.toString());

      setToast({
        message: 'Settings updated successfully!',
        type: 'success'
      });
    } catch (error) {
      console.error('Error updating settings:', error);
      setToast({
        message: 'Error updating settings',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="settings-container">
      <Sidebar />
      <div className="settings-content">
        <h1>Settings</h1>
        <form onSubmit={handleSaveSettings}> {/* Changed submit to handleSaveSettings */}
          <section className="settings-section">
            <h2>Profile Settings</h2>
            <div className="setting-group">
              <label>Email Notifications:</label>
              <input
                type="checkbox"
                checked={settings.emailNotifications}
                onChange={() => handleSettingChange('emailNotifications', !settings.emailNotifications)}
              />
            </div>
            <div className="setting-group">
              <label>Push Notifications:</label>
              <input
                type="checkbox"
                checked={settings.pushNotifications}
                onChange={() => handleSettingChange('pushNotifications', !settings.pushNotifications)}
              />
            </div>
            <div className="setting-group">
              <label>Dark Mode:</label>
              <input
                type="checkbox"
                checked={settings.darkMode}
                onChange={() => handleSettingChange('darkMode', !settings.darkMode)}
              />
            </div>
            <div className="setting-group">
              <label>Language:</label>
              <select
                value={settings.language}
                onChange={(e) => handleSettingChange('language', e.target.value)}
              >
                <option value="English">English</option>
                <option value="Spanish">Spanish</option>
                <option value="French">French</option>
              </select>
            </div>
            <div className="setting-group">
              <label>Time Zone:</label>
              <select
                value={settings.timeZone}
                onChange={(e) => handleSettingChange('timeZone', e.target.value)}
              >
                <option value="UTC">UTC</option>
                <option value="GMT">GMT</option>
                <option value="EST">EST</option>
                <option value="CST">CST</option>
                <option value="PST">PST</option>
              </select>
            </div>
            <div className="setting-group">
              <label>Session Duration:</label>
              <select
                value={settings.sessionDuration}
                onChange={(e) => handleSettingChange('sessionDuration', e.target.value)}
                className="session-duration-select"
              >
                {sessionOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
              <div className="settings-actions"> {/* Moved save button up */}
                <button type="submit" disabled={isLoading}>
                  {isLoading ? 'Saving...' : 'Save Settings'}
                </button>
              </div>
          </section>
          

          <section className="settings-section">
            <h2>Password</h2>
            <div className="setting-group">
              <label>New Password:</label>
              <input
                type="password"
                name="newPassword"
                value={passwords.newPassword}
                onChange={handlePasswordChange}
              />
            </div>
            <div className="setting-group">
              <label>Confirm Password:</label>
              <input
                type="password"
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={handlePasswordChange}
              />
            </div>
             <div className="setting-group">
              <button type="button" onClick={handlePasswordReset} disabled={isLoading}>
                  {isLoading ? 'Updating Password...' : 'Reset Password'}
                </button>
            </div>
          </section>
        </form>
      </div>
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
}

export default Settings;