import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut as firebaseSignOut,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { auth } from '../firebase';
import { ROLES } from '../utils/permissions';
import { createUserDocument } from './firestore';

export const signUp = async (email, password, additionalData = {}) => {
  console.log("Starting signup process for email:", email);
  console.log("Additional data provided:", additionalData);
  
  try {
    // Create the user in Firebase Auth
    console.log("Creating user in Firebase Auth...");
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    console.log("User created in Firebase Auth:", userCredential.user.uid);

    // Prepare user data with SUPER_ADMIN role
    const userData = {
      ...additionalData,
      email,
      role: ROLES.SUPER_ADMIN,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };
    console.log("Prepared user data with SUPER_ADMIN role:", userData);

    // Create the user document in Firestore
    console.log("Creating user document in Firestore...");
    await createUserDocument(userCredential.user, userData);
    console.log("User document created successfully with role:", ROLES.SUPER_ADMIN);

    return userCredential;
  } catch (error) {
    console.error("Error in signUp process:", error);
    throw error;
  }
};

export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signOut = () => {
  return firebaseSignOut(auth);
};

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
};
