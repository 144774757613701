import React, { useState, useEffect } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const AdminRevenueDashboard = () => {
  const [revenueStats, setRevenueStats] = useState({
    totalRevenue: 0,
    activeSubscriptions: 0,
    trialUsers: 0,
    monthlyData: []
  });

  useEffect(() => {
    const fetchRevenueData = async () => {
      try {
        const subscriptionsRef = collection(db, 'subscriptions');
        const subscriptionsSnap = await getDocs(subscriptionsRef);
        
        let total = 0;
        let active = 0;
        let trials = 0;
        const monthlyStats = {};

        subscriptionsSnap.forEach((doc) => {
          const data = doc.data();
          
          if (data.status === 'active') {
            total += 49.99; // Monthly subscription fee
            active++;
            
            // Calculate monthly stats
            const startDate = data.createdAt.toDate();
            const monthKey = `${startDate.getFullYear()}-${startDate.getMonth() + 1}`;
            monthlyStats[monthKey] = monthlyStats[monthKey] || { revenue: 0, subscriptions: 0 };
            monthlyStats[monthKey].revenue += 49.99;
            monthlyStats[monthKey].subscriptions++;
          }
          
          if (data.status === 'trial') {
            trials++;
          }
        });

        // Convert monthly stats to array for chart
        const monthlyData = Object.entries(monthlyStats).map(([month, stats]) => ({
          month,
          revenue: stats.revenue,
          subscriptions: stats.subscriptions
        })).sort((a, b) => a.month.localeCompare(b.month));

        setRevenueStats({
          totalRevenue: total,
          activeSubscriptions: active,
          trialUsers: trials,
          monthlyData
        });
      } catch (error) {
        console.error('Error fetching revenue data:', error);
      }
    };

    fetchRevenueData();
  }, []);

  return (
    <div className="admin-revenue-dashboard">
      <div className="stats-grid">
        <div className="stat-card">
          <h3>Total Monthly Revenue</h3>
          <p className="stat-value">${revenueStats.totalRevenue.toFixed(2)}</p>
        </div>
        <div className="stat-card">
          <h3>Active Subscriptions</h3>
          <p className="stat-value">{revenueStats.activeSubscriptions}</p>
        </div>
        <div className="stat-card">
          <h3>Trial Users</h3>
          <p className="stat-value">{revenueStats.trialUsers}</p>
        </div>
        <div className="stat-card">
          <h3>Average Revenue Per User</h3>
          <p className="stat-value">
            ${revenueStats.activeSubscriptions ? 
              (revenueStats.totalRevenue / revenueStats.activeSubscriptions).toFixed(2) : 
              '0.00'}
          </p>
        </div>
      </div>

      <div className="revenue-chart">
        <h3>Monthly Revenue Trends</h3>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={revenueStats.monthlyData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip />
            <Legend />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="revenue"
              stroke="#8884d8"
              name="Revenue ($)"
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="subscriptions"
              stroke="#82ca9d"
              name="Subscriptions"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AdminRevenueDashboard;
