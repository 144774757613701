import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useAuth } from '../hooks/useAuth';
import './HamburgerMenu.css';

function HamburgerMenu({ isOpen, visibleLinks, newNotificationsCount, toggleSidebar }) {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const menuRef = useRef();

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (!event.target.closest('.hamburger-menu')) {
          toggleSidebar();
        }
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  if (!isOpen) return null;

  return (
    <div className="hamburger-menu-dropdown" ref={menuRef}>
      <ul className="hamburger-menu-list">
        <li><Link to="/welcome">👋 Welcome</Link></li>
        <li><Link to="/home">🏠 Home</Link></li>
        <li><Link to="/times">⏱️ Times</Link></li>
        <li><Link to="/messages">✉️ Messages</Link></li>
        <li>
          <Link to="/notifications">
            🔔 Notifications
            {newNotificationsCount > 0 && (
              <span className="notification-count">{newNotificationsCount}</span>
            )}
          </Link>
        </li>
        {user && ['admin', 'supervisor', 'Supervisor'].includes(user.role) && (
          <>
            <li><Link to="/dashboard">🎛️ Dashboard</Link></li>
            <li><Link to="/dispatch">📟 Dispatch</Link></li>
            <li><Link to="/reports">📊 Reports</Link></li>
            <li><Link to="/crew-members">👥 Crew Members</Link></li>
            <li><Link to="/departments">🎭 Departments</Link></li>
            <li><Link to="/vehicles-page">🚛 Production Vehicles</Link></li>
            <li><Link to="/budget">🧮 Budget</Link></li>
          </>
        )}
        <li><Link to="/profile">🪪 Profile</Link></li>
        <li><Link to="/settings">⚙️ Settings</Link></li>
        <li><button onClick={handleLogout}>Logout</button></li>
      </ul>
    </div>
  );
}

export default HamburgerMenu;
